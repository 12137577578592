var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" Акты "), _c('v-spacer'), _vm.$helper.permission.canCreateContract(_vm.user) ? _c('ActDialog', {
    attrs: {
      "id": _vm.id,
      "contract": _vm.contract,
      "lawyers": _vm.lawyers,
      "lawyersOGPA": _vm.lawyersOGPA,
      "lawyersEPO": _vm.lawyersEPO,
      "actTypes": _vm.actTypes
    },
    on: {
      "saved": _vm.onItemAdd
    }
  }) : _vm._e()], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "items-per-page": 50
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_vm._l(items, function (item) {
          var _item$type, _item$type2, _item$type3;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            staticClass: "px-5",
            attrs: {
              "width": "20"
            }
          }, [_vm.$helper.permission.canDeleteRequest(item, _vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(_vm._s(_vm.$moment(item.date).dateFormat()))]), _c('td', {
            attrs: {
              "width": "150"
            }
          }, [_vm._v(_vm._s(item.type.text))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(_vm._s(item.signDate))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_c('router-link', {
            attrs: {
              "to": "/acts/".concat(item.id, "/edit")
            }
          }, [_vm._v(_vm._s(item.number))])], 1), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [((_item$type = item.type) === null || _item$type === void 0 ? void 0 : _item$type.value) === 'ACT' ? _c('span', [_vm._v(_vm._s(item.amount))]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [((_item$type2 = item.type) === null || _item$type2 === void 0 ? void 0 : _item$type2.value) === 'ACT' ? _c('span', [_vm._v(" " + _vm._s(_vm.$helper.util.act.getPercentAndBonusForAct(item, _vm.contract)) + " ")]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [((_item$type3 = item.type) === null || _item$type3 === void 0 ? void 0 : _item$type3.value) === 'PAYOUT' ? _c('span', [_vm._v(_vm._s(item.amount))]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "200"
            }
          }), _c('td', [item.createdBy ? _c('div', [_vm._v(_vm._s(item.createdBy.fullName))]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [item.installment ? _c('div', [_vm._v(_vm._s(_vm.$helper.getInstallmentName(item.installment)))]) : _vm._e()]), _c('td', [item.comment ? _c('div', [_vm._v(_vm._s(item.comment))]) : _vm._e()])]);
        }), _c('tr', {
          staticClass: "result-row"
        }, _vm._l(_vm.headers, function (header, i) {
          return _c('td', {
            key: i
          }, [i == 0 ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value == 'amountAct' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalActAmount)) + " ")]) : _vm._e(), header.value == 'bonus' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalActBonus)) + " ")]) : _vm._e(), header.value == 'amountPayout' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalPayoutAmount)) + " ")]) : _vm._e(), header.value == 'remainder' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.contractPaymentRemainder)) + " ")]) : _vm._e()]);
        }), 0)], 2)];
      }
    }], null, false, 4118598442)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }