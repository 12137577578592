<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить акт/выплату
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить акт/выплату</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.type"
                            label="Тип"
                            :items="actTypes"
                            clearable
                            return-object
                            item-value="value"
                            item-text="text"
                        ></v-select>
                        <v-alert v-if="form.errors.has('type')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('type') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="form.number" label="Номер"></v-text-field>
                        <v-alert v-if="form.errors.has('number')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('number') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="form.amount" label="Сумма"></v-text-field>
                        <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('amount') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyer"
                            label="Юрист/доп. юрист"
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                        <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyer') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerEPO"
                            label="Юрист ЭПО"
                            :items="lawyersEPO"
                            clearable
                            item-value="id"
                            item-text="fullName"></v-select>
                        <v-alert v-if="form.errors.has('lawyerEPO')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyerEPO') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerOGPA"
                            label="Юрист ОГПиА"
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                        <v-alert v-if="form.errors.has('lawyerOGPA')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyerOGPA') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerOGPAAssistant"
                            label="Помощник юриста ОГПиА"
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                        <v-alert v-if="form.errors.has('lawyerOGPAAssistant')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyerOGPAAssistant') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-dialog
                            ref="signDateDialog"
                            v-model="signDateDialog"
                            :return-value.sync="form.signDate"
                            persistent
                            width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="form.signDate"
                                    label="Дата подписания"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.signDate"
                                type="date"
                                scrollable
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="signDateDialog = false">Отмена</v-btn>
                                <v-btn
                                    text color="primary"
                                    @click="$refs.signDateDialog.save(form.signDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <v-alert v-if="form.errors.has('signDate')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('signDate') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="form.comment"
                            label="Примечание"
                            rows="2">
                        </v-textarea>
                        <v-alert v-if="form.errors.has('comment')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('comment') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            auto-grow
                            v-model="form.text"
                            label="Комментарий">
                        </v-textarea>
                        <v-alert v-if="form.errors.has('text')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('text') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            class="chekbox__blue"
                            value="HALVA"
                            v-model="form.installment"
                            label="Халва">
                        </v-checkbox>
                        <v-alert v-if="form.errors.has('installment')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('installment') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import ContractService from "../../_services/ContractService";

export default {
    props: {
        id: Number,
        contract: Object,
        lawyers: Array,
        lawyersOGPA: Array,
        lawyersEPO: Array,
        actTypes: Array,
    },
    data() {
        return {
            form: this.contract
                ? new Form({
                    lawyer: this.contract.lawyer,
                    lawyerEPO: this.contract.lawyerEPO,
                    lawyerOGPA: this.contract.lawyerOGPA,
                    lawyerOGPAAssistant: this.contract.lawyerOGPAAssistant,
                })
                : new Form({}),
            dialog: false,
            signDateDialog: false
        }
    },
    methods: {
        save() {
            ContractService.addAct(this.id, this.form).then(() => {
                this.dialog = false;
                this.$emit('saved');
            });
        },
    }
};
</script>
