import Vue from 'vue'
import {config} from "./constants";
import {reportHelper} from "./report/reportHelper";
import moment from "moment";

const storage = window.localStorage;
export const helper = {};

// prototypes
Array.prototype.insert = function (index) {
    this.splice.apply(this, [index, 0].concat(
        Array.prototype.slice.call(arguments, 1)));
    return this;
};

/**
 * report helper
 */
helper.report = reportHelper;

/**
 * localStorage
 */
helper.ls = {
    set(key, value) {
        value = JSON.stringify(value);
        storage.setItem(key, value)
    },
    get(key, defaultValue) {
        let value = storage.getItem(key);
        if (value === null || value === 'undefined' || value === '') {
            value = defaultValue
        } else {
            value = JSON.parse(value)
        }
        return value
    },
    remove(key) {
        storage.removeItem(key)
    }
};

/**
 * a wrapper for helper.ls
 */
helper.store = (key, value) => {
    if (value === undefined) {
        return helper.ls.get(key)
    } else {
        return helper.ls.set(key, value)
    }
};

helper.url = {
    getRelative(url) {
        return url.replace(process.env.VUE_APP_API_URL, "");
    }
};

helper.removeOne = (array, predicate) => {
    for (var i = 0; i < array.length; i++) {
        if (predicate(array[i])) {
            return array.splice(i, 1);
        }
    }
};

helper.remove = (array, predicate) => {
    var removed = [];

    for (var i = 0; i < array.length;) {

        if (predicate(array[i])) {
            removed.push(array.splice(i, 1));
            continue;
        }
        i++;
    }
    return removed;
};

helper.convertArrayToObject = (array, key, value) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        const itemVal = (value != undefined ? value : item);
        return {
            ...obj,
            [item[key]]: itemVal,
        };
    }, initialValue);
};

helper.calcTableHeight = () => {
    return window.innerHeight - 244;
};

helper.calcTableHeightWithHorizontalScroll = () => {
    return helper.calcTableHeight() - 20;
};

let defaultRequestColumns = [
    {sortable: false},
    {text: 'ID', value: 'id'},
    {text: 'Статус', value: 'state'},
    {text: 'Источник', value: 'source'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Регистрация заявки', value: 'createdAt'},
    {text: 'Распределение заявки', value: 'assignedAt'},
    {text: 'Оператор', value: 'operator'},
    {text: 'Клиент', value: 'userName'}
];
helper.requestGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'OPERATOR':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация заявки', value: 'createdAt'},
                    {text: 'Распределение заявки', value: 'assignedAt'},
                    {text: 'Клиент', value: 'userName'}
                ];
                if (process.env.VUE_APP_HIDE_SOURCE_TO_OPERATORS !== 'true') {
                    columns = columns.insert(3, {text: 'Источник', value: 'source'});
                }
                break;
            case 'PROVIDER':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация заявки', value: 'createdAt'},
                    {text: 'Распределение заявки', value: 'assignedAt'},
                    {text: 'Клиент', value: 'userName'}
                ];
                break;
            case 'OPERATOR_MANAGER':
                columns = defaultRequestColumns;
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Контрагент', value: 'counterpart'},
                        {text: 'Город', value: 'city'}
                    ]);
                }
                break;
            case 'ADMIN':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Источник', value: 'source'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация заявки', value: 'createdAt'},
                    {text: 'Распределение заявки', value: 'assignedAt'},
                    {text: 'Оператор', value: 'operator'}
                ];
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Контрагент', value: 'counterpart'},
                        {text: 'Город', value: 'city'},
                        {text: 'Подразделение', value: 'subdivision'}
                    ]);
                }
                break;
            case 'COUNTERPART':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Дата', value: 'assignedAt'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'Город', value: 'city'},
                ];
                break;
            default:
                columns = defaultRequestColumns;
                break;
        }
        return columns;
    }
};

let defaultClientColumns = [
    {sortable: false},
    {sortable: false, value: 'inFavorites'},
    {text: 'ID', value: 'id'},
    {text: 'Клиент', value: 'userName'},
    {text: 'Доверитель', value: 'principal'},
    {text: 'Онлайн', value: 'isOnline'},
    {text: 'Вторичный', value: 'isDouble'},
    {text: 'Статус', value: 'state'},
    {text: 'Дата события', value: 'callbackDate'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Регистрация клиента', value: 'createdAt'},
    {text: 'Распределение клиента', value: 'assignedAt'},
    {text: 'Последний визит', value: 'lastVisitAt'},
    {text: 'Юрист', value: 'lawyer'},
    {text: 'Доп. юрист', value: 'additionalLawyer'},
    {text: 'Пом. юриста', value: 'lawyerAssistant'},
    {text: 'Комментарий', value: 'comment'},
    {text: 'Область права', value: 'area'},
    {text: 'Примечание', value: 'reportComment'},
];
helper.clientGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = [
                    {sortable: false},
                    {sortable: false, value: 'inFavorites'},
                    {text: 'ID', value: 'id'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'От кого', value: 'fromSource'},
                    {text: 'Доверитель', value: 'principal'},
                    {text: 'Онлайн', value: 'isOnline'},
                    {text: 'Вторичный', value: 'isDouble'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Дата события', value: 'callbackDate'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация клиента', value: 'createdAt'},
                    {text: 'Распределение клиента', value: 'assignedAt'},
                    {text: 'Последний визит', value: 'lastVisitAt'},
                    {text: 'Юрист', value: 'lawyer'},
                    {text: 'Доп. юрист', value: 'additionalLawyer'},
                    {text: 'Пом. юриста', value: 'lawyerAssistant'},
                    {text: 'Комментарий', value: 'comment'},
                    {text: 'Область права', value: 'area'},
                    {text: 'Офис', value: 'office'},
                    {text: 'Примечание', value: 'reportComment'},
                ]
                break;
            default:
                columns = defaultClientColumns;
                break;
        }
        return columns;
    }
};

let defaultContractColumns = [
    {sortable: false, text: ' ', value: 'action', width: 30},
    {sortable: false, text: 'Избр.', value: 'inFavorites', width: 30},
    {text: 'ID', value: 'id', width: 110},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Статус', value: 'state', width: 160},
    {text: 'Этап исполнения', value: 'phase', width: 160},
    {text: 'Клиент', value: 'userName', width: 100},
    {text: 'Доверитель', value: 'principal', width: 160},
    //{text: 'Допродажа', value: 'isResale'},
    //{text: 'Расторжение', value: 'isTermination'},
    //{text: 'Создан', value: 'createdAt'},
    {text: 'Зарегистрирован', value: 'date', width: 170},
    {text: 'Сумма', value: 'amount', width: 130},
    {text: 'Оплачено', value: 'alreadyPayed', width: 130},
    {text: 'Остаток', value: 'payedAmount', width: 130},
    {text: 'Доплаты', value: 'additionalPayments', width: 200},
    {text: 'Освоенная сумма', value: 'earnedAmount', width: 130},
    {text: 'Остаток по актам', value: 'actPaymentRemainder', width: 200},
    {text: 'Вид', value: 'type', width: 100},
    {text: 'Предмет', value: 'subject', width: 100},
    {text: 'Дата суда', value: 'courtDate', width: 170},
    {text: 'Юрист ОГПиА', value: 'lawyerOGPA', width: 260},
    {text: 'Нагрузка ОГПиА', value: 'isPaymentOGPA', width: 140},
    {text: 'Контроль сотрудника', value: 'isEmployeeControl', width: 140},
    {text: 'Доп. юрист ОГПиА', value: 'additionalLawyerOGPA', width: 260},
    {text: 'Пом. юриста ОГПиА', value: 'lawyerOGPAAssistant', width: 260},
    {text: 'Юрист', value: 'lawyer', width: 260},
    {text: 'Доп. юрист', value: 'additionalLawyer', width: 260},
    {text: 'Пом. юриста', value: 'lawyerAssistant', width: 260},
    // {text: 'Безнал', value: 'isBezNal'},
    {text: 'Юрист ЭПО', value: 'lawyerEPO', width: 260},
    {text: 'Юрист ЭПО (суд)', value: 'additionalLawyerEPO', width: 260},
    {text: 'Прочие расходы', value: 'transport', width: 110},
    {text: 'Отпр. на подг. док.', value: 'sentForDocumentsPreparationAt', width: 220},
    {text: 'Дата готовности пакета', value: 'taskLegalExpert.readyDate', width: 220},
    {text: 'Дата готовности пакета (доп.)', value: 'taskAddLegalExpert.readyDate', width: 220},
    {text: 'Док. готовы', value: 'documentsAreReadyAt', width: 220},
    {text: 'Передано на подачу в суд', value: 'transferredToCourtActions', width: 220},
    {text: 'Подано в суд', value: 'sentToCourtActions', width: 220},
    {text: 'Передано в ОГПиА', value: 'transferredToOGPAAt', width: 220},
    {text: 'Пакет от ЛенЮр', value: 'packageFromLenyrActions', width: 220},
    {text: 'Область права', value: 'area', width: 160},
    {text: 'Примечание', value: 'comment', width: 230},
];
helper.contractGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = defaultContractColumns;
                break;
            case 'BUHGALTER':
                columns = [
                    {sortable: false, text: '-', value: 'action', width: 30},
                    {text: 'ID', value: 'id', width: 110},
                    {text: 'Номер', value: 'number', width: 120},
                    {text: 'Статус', value: 'state', width: 160},
                    {text: 'Этап исполнения', value: 'phase', width: 160},
                    {text: 'Клиент', value: 'userName', width: 100},
                    {text: 'Доверитель', value: 'principal', width: 160},
                    {text: 'Зарегистрирован', value: 'date', width: 170},
                    {text: 'Сумма', value: 'amount', width: 130},
                    {text: 'Оплачено', value: 'alreadyPayed', width: 130},
                    {text: 'Остаток', value: 'payedAmount', width: 130},
                    {text: 'Доплаты', value: 'additionalPayments', width: 200},
                    {text: 'Остаток по актам', value: 'actPaymentRemainder', width: 200},
                    {text: 'Вид', value: 'type', width: 100},
                    //{text: 'Безнал', value: 'isBezNal'},
                    {text: 'Предмет', value: 'subject', width: 100},
                    {text: 'Прочее Д/С', value: 'transport', width: 110},
                    {text: 'Коммент.', value: 'commentForBuhgalter', width: 230}
                ];
                break;
            default:
                columns = defaultContractColumns;
                break;
        }
        return columns;
    },
    getPropertiesForRole(role) {
        let props = {
            tableWidth: '7180px'
        };
        switch (role) {
            case 'BUHGALTER':
                props = {
                    tableWidth: '2420px'
                };
                break;
        }
        return props;
    }
};

let defaultContractOKKColumns = [
    {sortable: false, text: ' ', value: 'action', width: 30},
    {sortable: false, text: 'Избр.', value: 'inFavorites', width: 30},
    {text: 'ID', value: 'id', width: 110},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Статус', value: 'state', width: 160},
    {text: 'Этап исполнения', value: 'phase', width: 160},
    {text: 'Клиент', value: 'userName', width: 100},
    {text: 'Доверитель', value: 'principal', width: 160},
    {text: 'Передано в ОКК', value: 'sentToOkkActions', sortable: false, width: 220},
    {text: 'Дата поступления заявления', value: 'flow.applicationDate', sortable: false, width: 200},
    {text: 'Дата направления ответа', value: 'flow.answerDate', sortable: false, width: 200},
    {text: 'Дата возврата денежных средств', value: 'flow.moneyBackDate', sortable: false, width: 200},
    {text: 'Зарегистрирован', value: 'date', width: 170},
    {text: 'Сумма', value: 'amount', width: 130},
    {text: 'Оплачено', value: 'alreadyPayed', width: 130},
    {text: 'Освоенная сумма', value: 'earnedAmount', sortable: false, width: 130},
    {text: 'Доплаты', value: 'additionalPayments', width: 200},
    {text: 'Остаток', value: 'payedAmount', width: 130},
    {text: 'Возвраты', value: 'vozvrat', sortable: false, width: 200},
    {text: 'Вид', value: 'type', width: 100},
    {text: 'Предмет', value: 'subject', width: 100},
    {text: 'Дата суда', value: 'courtDate', width: 170},
    {text: 'Отправил в ОКК', value: 'sentToOKKBy', width: 260},
    {text: 'Юрист ОГПиА', value: 'lawyerOGPA', width: 260},
    {text: 'Юрист', value: 'lawyer', width: 260},
    {text: 'Доп. юрист', value: 'additionalLawyer', width: 260},
    {text: 'Юрист ЭПО', value: 'lawyerEPO', width: 260},
    {text: 'Прочее Д/С', value: 'transport', width: 110},
    {text: 'Отпр. на подг. док.', value: 'sentForDocumentsPreparationAt', width: 220},
    {text: 'Док. готовы', value: 'documentsAreReadyAt', width: 220},
    {text: 'Передано в ОГПиА', value: 'transferredToOGPAAt', width: 220},
    {text: 'Область права', value: 'area', width: 160},
    {text: 'Примечание', value: 'comment', width: 230},
];
helper.contractOKKGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = defaultContractOKKColumns;
                break;
            case 'BUHGALTER':
                columns = [
                    {sortable: false, text: ' ', value: 'action', width: 30},
                    {text: 'ID', value: 'id', width: 110},
                    {text: 'Номер', value: 'number', width: 120},
                    {text: 'Статус', value: 'state', width: 160},
                    {text: 'Этап исполнения', value: 'phase', width: 160},
                    {text: 'Клиент', value: 'userName', width: 100},
                    {text: 'Доверитель', value: 'principal', width: 160},
                    {text: 'Зарегистрирован', value: 'date', width: 170},
                    {text: 'Сумма', value: 'amount', width: 130},
                    {text: 'Оплачено', value: 'alreadyPayed', width: 130},
                    {text: 'Остаток', value: 'payedAmount', width: 130},
                    {text: 'Возвраты', value: 'vozvrat', sortable: false, width: 200},
                    {text: 'Вид', value: 'type', width: 100},
                    {text: 'Предмет', value: 'subject', width: 100},
                    {text: 'Прочее Д/С', value: 'transport', width: 110},
                    {text: 'Доплаты', value: 'additionalPayments', width: 200},
                    {text: 'Коммент.', value: 'commentForBuhgalter', width: 230}
                ];
                break;
            default:
                columns = defaultContractOKKColumns;
                break;
        }
        return columns;
    },
    getPropertiesForRole(role) {
        let props = {
            tableWidth: '5290px'
        };
        switch (role) {
            case 'BUHGALTER':
                props = {
                    tableWidth: '1820px'
                };
                break;
        }
        return props;
    }
};

let defaultContractPaymentColumns = [
    {sortable: false},
    {text: 'ID', value: 'contract.id'},
    {text: 'Номер', value: 'contract.number'},
    {text: 'Статус', value: 'contract.state'},
    {text: 'Клиент', value: 'contract.userName'},
    //{text: 'Доверитель', value: 'contract.principal'},
    {text: 'Дата оплаты', value: 'date'},
    {text: 'Оплачено', value: 'amount'},
    //{text: 'Оплачено', value: 'contract.payedAmount'},
    {text: 'Остаток', value: 'contract.payedLeft', sortable: false},
    {text: 'Возвраты', value: 'vozvrat', sortable: false},
    {text: 'Прочее Д/С', value: 'transport', sortable: false},
    {text: 'Форма оплаты', value: 'isBezNal'},
    {text: 'Б/тип', value: 'bezNalType'},
    {text: 'Наличные тип', value: 'nalType'},
    {text: 'Коммент.', value: 'comment'},
    {text: 'Юрист', value: 'contract.lawyer'},
    {text: 'Доп. юрист', value: 'contract.additionalLawyer'},
    {text: 'Вид', value: 'contract.type'},
    {text: 'Предмет', value: 'contract.subject'},
    {text: 'Доплаты', value: 'contract.additionalPayments', sortable: false},
    //{text: 'Примечание', value: 'contract.comment'},
];
helper.contractPaymentGridColumns = {
    getForRole(role) {
        let columns = [];
        const payFromIndex = defaultContractPaymentColumns.findIndex(item => item.text === 'Наличные тип');
        const oldColumns = [...defaultContractPaymentColumns]
        switch (role) {
            case 'ADMIN':
            case 'RECEPTION':
                oldColumns.splice(payFromIndex + 1, 0, {text: 'Бухгалтер.', value: 'isSentForBuhgalter'});
                columns = oldColumns;
                break;
            case 'BUHGALTER':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'contract.id'},
                    {text: 'Номер', value: 'contract.number'},
                    {text: 'Статус', value: 'contract.state'},
                    {text: 'Клиент', value: 'contract.userName'},
                    //{text: 'Доверитель', value: 'contract.principal'},
                    {text: 'Дата оплаты', value: 'date'},
                    {text: 'Оплачено', value: 'amount'},
                    //{text: 'Оплачено', value: 'contract.payedAmount'},
                    {text: 'Остаток', value: 'contract.payedLeft', sortable: false},
                    {text: 'Возвраты', value: 'vozvrat', sortable: false},
                    {text: 'Прочее Д/С', value: 'transport', sortable: false},
                    {text: 'Форма оплаты', value: 'isBezNal'},
                    //{text: 'Предмет', value: 'contract.subject'},
                    {text: 'Коммент.', value: 'comment'},
                    {text: 'Доплаты', value: 'contract.additionalPayments', sortable: false},
                    {text: 'Коммент. бух', value: 'commentForBuhgalter'}
                ];
                break;
            default:
                columns = defaultContractPaymentColumns;
                break;
        }
        return columns;
    },
    getPropertiesForRole(role) {
        let props = {
            tableWidth: '2920px'
        };
        switch (role) {
            case 'BUHGALTER':
                props = {
                    tableWidth: '1230px'
                };
                break;
        }
        return props;
    }
};

let defaultContract__ContractPaymentColumns = [
    {sortable: false},
    {text: 'Дата', value: 'createdAt'},
    {text: 'Дата оплаты', value: 'date'},
    {text: 'Оплачено', value: 'amount'},
    {text: 'Возвраты', value: 'vozvrat', sortable: false},
    {text: 'Прочее Д/С', value: 'transport', sortable: false},
    {text: 'Форма оплаты', value: 'isBezNal'}
];

helper.contract__ContractPaymentColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
            case 'RECEPTION':
                columns = defaultContract__ContractPaymentColumns.concat([
                    {text: 'Бухгалтер.', value: 'isSentForBuhgalter'},
                    {text: 'Коммент.', value: 'comment'}
                ]);
                break;
            case 'BUHGALTER':
                columns = defaultContract__ContractPaymentColumns.concat([
                    {text: 'Коммент.', value: 'comment'},
                    {text: 'Коммент. бух', value: 'commentForBuhgalter'}
                ]);
                break;
            default:
                columns = defaultContract__ContractPaymentColumns;
                break;
        }
        return columns;
    }
};

let defaultActColumns = [
    {sortable: false},
    {sortable: false, value: 'inFavorites'},
    {text: 'ID', value: 'id'},
    {text: 'Дата', value: 'date'},
    // {text: 'Дата подписания', value: 'signDate'},
    {text: 'Тип', value: 'type'},
    {text: 'Номер', value: 'number'},
    {text: 'Номер договора', value: 'contract.number'},
    {text: 'Дата договора', value: 'contract.date'},
    // {text: 'Вид', value: 'contract.type'},
    {text: 'Клиент', value: 'contract.client'},
    {text: 'Юрист', value: 'contract.lawyer'},
    {text: 'Юрист ОГПиА', value: 'contract.lawyerOGPA'},
    {text: 'Пом. юриста ОГПиА (акт)', value: 'lawyerOGPAAssistant'},
    {text: 'Юрист ЭПО (акт)', value: 'lawyerEPO'},
    {text: 'Юрист ОГПиА (акт)', value: 'lawyerOGPA'},
    {text: 'Сумма актов', value: 'amountAct'},
    {text: '% бонус', value: 'bonus'},
    {text: 'Сумма выплат', value: 'amountPayout'},
    {text: 'Остаток', value: 'remainder'},
    // {text: 'Создал', value: 'createdBy'},
    {text: 'Рассрочка', value: 'installment'},
    {text: 'Примечание', value: 'comment'},
];
helper.actGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = defaultActColumns;
                break;
            case 'BUHGALTER':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Дата', value: 'date'},
                    {text: 'Дата подписания', value: 'signDate'},
                    {text: 'Тип', value: 'type'},
                    {text: 'Номер', value: 'number'},
                    {text: 'Номер договора', value: 'contract.number'},
                    {text: 'Предмет', value: 'contract.subject'},
                    {text: 'Клиент', value: 'contract.client'},
                    {text: 'Юрист', value: 'contract.lawyer'},
                    {text: 'Юрист ОГПиА', value: 'contract.lawyerOGPA'},
                    {text: 'Сумма актов', value: 'amountAct'},
                    {text: 'Сумма', value: 'amount'},
                    {text: 'Остаток', value: 'remainder'},
                    {text: 'Создал', value: 'createdBy'},
                    {text: 'Примечание', value: 'comment'},
                ];
                break;
            default:
                columns = defaultActColumns;
                break;
        }
        return columns;
    },
    getPropertiesForRole(role) {
        let props = {
            tableWidth: '2800px'
        };
        switch (role) {
            case 'BUHGALTER':
                props = {
                    tableWidth: '2500px'
                };
                break;
        }
        return props;
    }
};
helper.util = {
    act: {
        getPercentAndBonusForAct: function (act, contract) {
            const bonusForAct = this.getBonusForAct(act, contract);
            const contractBonusPercent = this.getContractBonus(act, contract);
            return `${bonusForAct} (${contractBonusPercent}%)`;
        },
        getBonusForAct: function (act, contract) {
            const contractBonusPercent = this.getContractBonus(act, contract);
            let bonus = 0;
            if (contractBonusPercent) {
                bonus = act?.amount * contractBonusPercent / 100;
            }
            return bonus || 0;
        },
        getContractBonus: function (act, contract) {
            const actLawyerId = act?.lawyer?.id;
            const contractLawyerId = contract?.lawyer?.id || contract?.lawyer;
            const contractAdditionalLawyerId = contract?.additionalLawyer?.id || contract?.additionalLawyer;

            let contractBonusPercent = 0;
            if (actLawyerId === contractLawyerId) {
                contractBonusPercent = contract?.lawyerBonus || 0;
            } else if (actLawyerId === contractAdditionalLawyerId) {
                contractBonusPercent = contract?.additionalLawyerBonus || 0;
            }
            return contractBonusPercent || 0;
        }
    }
}

let defaultAppointmentColumns = [
    {sortable: false},
    {sortable: false},
    {text: 'Договор', value: 'contract'},
    {text: 'ID', value: 'id'},
    {text: 'Статус', value: 'state'},
    {text: 'Онлайн', value: 'isOnlineAppointment'},
    {text: 'Время записи', value: 'appointmentDate'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Оператор', value: 'operator'},
    //{text: 'Юрист', value: 'lawyer'}
];
helper.appointmentGridColumns = {
    getForRole(role) {
        let columns;
        switch (role) {
            case 'OPERATOR':
                columns = [
                    {sortable: false},
                    {sortable: false},
                    {text: 'Договор', value: 'contract'},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Онлайн', value: 'isOnlineAppointment'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Телефон', value: 'userPhone'}
                ];
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Офис', value: 'office'}
                    ]);
                }
                break;
            case 'RECEPTION':
                columns = [
                    {sortable: false},
                    {sortable: false},
                    {text: 'Договор', value: 'contract'},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Онлайн', value: 'isOnlineAppointment'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'Оператор', value: 'operator'}
                ];
                break;
            case 'RECEPTION_COUNTERPARTY':
                columns = [
                    {sortable: false},
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'Оператор', value: 'operator'}
                ];
                break;
            case 'ADMIN':
                columns = [
                    {sortable: false},
                    {sortable: false},
                    {text: 'Договор', value: 'contract'},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Онлайн', value: 'isOnlineAppointment'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Оператор', value: 'operator'},
                ];
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Подразделение', value: 'subdivision'},
                        {text: 'Офис', value: 'office'}
                    ]);
                }
                break;
            case 'OPERATOR_MANAGER':
                columns = defaultAppointmentColumns;
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Офис', value: 'office'}
                    ]);
                }
                break;
            default:
                columns = defaultAppointmentColumns;
                break;
        }
        return columns;
    }
};

helper.callsGridColumns = {
    getForRole(role) {
        let columns;
        switch (role) {
            case 'ADMIN':
            case 'OPERATOR_MANAGER':
            case 'OPERATOR':
                columns = [
                    {text: 'Дата', value: 'createTime'},
                    {text: 'Направление', value: 'direction'},
                    {text: 'Сотрудник', value: 'user'},
                    {text: 'Длительность', value: 'time'},
                    {text: 'Записи', sortable: false}
                ];
                break;
            default:
                columns = [
                    {text: 'Дата', value: 'createTime'},
                    {text: 'Направление', value: 'direction'},
                    {text: 'Сотрудник', value: 'user'},
                    {text: 'Длительность', value: 'time'}
                ];
                break;
        }
        return columns;
    }
};


let defaultPaymentsColumns = [
    {sortable: false},
    {text: 'Дата', value: 'createdAt'},
    {text: 'Сумма', value: 'paidAmount'},
    {text: 'Стоимость заявки', value: 'requestPrice'},
    {text: 'Кол-во заявок', value: 'requestQuantity'},
    {text: 'Остаток на балансе', value: 'remainingBalance'}
];
helper.paymentsGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Аккаунт', value: 'account'},
                    {text: 'Дата', value: 'createdAt'},
                    {text: 'Сумма', value: 'paidAmount'},
                    {text: 'Стоимость заявки', value: 'requestPrice'},
                    {text: 'Кол-во заявок', value: 'requestQuantity'},
                    {text: 'Остаток на балансе', value: 'remainingBalance'},
                    {text: 'Комментарий', value: 'comment'}
                ];
                break;
            default:
                columns = defaultPaymentsColumns;
                break;
        }
        return columns;
    }
};

helper.isUserRole = (user, role) => {
    return user.role === role;
};

helper.env = {
    isTrue: (value) => {
        console.log(process.env);
        return process.env[value] === 'true';
    }
};

helper.showError = (error) => {
    error = error.response;
    console.log(error.status);
    const errorMsg = "Ошибка!";
    const statusMsg = config.errorStatusesMessages[error.status];
    let errorDetail = statusMsg ? statusMsg : "Произошла непредвиденная ошибка. Попробуйте обновить страницу.";
    errorDetail = error.data && error.data.message ? error.data.message : errorDetail;
    return errorMsg + " " + error.status + ": " + errorDetail;
};

helper.permission = {

    // Requests

    // eslint-disable-next-line no-unused-vars
    canOverview(user) {
        return true;//user.role !== 'RECEPTION';
    },
    canSeeNewRequestNotification(user) {
        return user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR'
            || user.role === 'COUNTERPART';
    },
    canSeeNewClientNotification(user) {
        return user.role === 'LAWYER_MANAGER'
            || user.role === 'LAWYER';
    },
    canDeleteRequest(request, user) {
        return (user.role === 'ADMIN' && !request.hasProviders)
            || user.role === 'PROVIDER';
    },
    canDeleteBulkRequest(user) {
        return user.role === 'ADMIN';
    },
    canAssignRequest(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER';
    },
    canAssignCounterpart(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER';
    },
    canAssignClient(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'RECEPTION';
    },
    canSeeRequest(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR'
            || user.role === 'PROVIDER'
            || user.role === 'COUNTERPART';
    },
    canSeeCreatedAtOfRequest(user) {
        return user.role !== 'COUNTERPART';
    },
    canEditRequest(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR'
            || user.role === 'PROVIDER'
            || user.role === 'COUNTERPART';
    },
    canMakeAppointments(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR';
    },
    canSeeAppointments(user) {
        return user.role === 'RECEPTION'
            || user.role === 'RECEPTION_COUNTERPARTY'
            || user.role === 'LAWYER_MANAGER';
    },
    canSeeClientOnMain(user) {
        return user.role === 'LAWYER';
    },
    canSetComingState(user) {
        return user.role === 'RECEPTION'
            || user.role === 'RECEPTION_COUNTERPARTY'
            || user.role === 'ADMIN';
    },
    canSeeCalls(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR';
    },
    canSeeRequestAnswers(user) {
        return user.role !== 'COUNTERPART';
    },
    canAssignLawyer(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'RECEPTION';
    },
    canSeeOverview(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'OPERATOR';
    },
    canChangeNotManualState(user, state) {
        if (state === 'ZAPIS') {
            return false;
        }
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || user.role === 'PROVIDER';
    },
    canSeeSourceOfRequest(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER'
            || (user.role === 'OPERATOR' && process.env.VUE_APP_HIDE_SOURCE_TO_OPERATORS !== 'true');
    },
    canSetState(user, state) {
        if (state.roles.length > 0) {
            return state.roles.indexOf(user.role) >= 0;
        }
        return true;
    },
    canMakeCallback(user) {
        return user.innerPhoneNumbers.length > 0;
    },
    canSeeMoreRequestsOnGrid(user) {
        return user.role === 'ADMIN'
            || user.role === 'PROVIDER'
            || user.role === 'OPERATOR_MANAGER';
    },
    canChangeSubdivision(user) {
        return helper.env.isTrue('VUE_APP_USE_SUBDIVISIONS') && user.role === 'ADMIN'
    },

    // Clients
    canDeleteClient(client, user) {
        return user.role === 'ADMIN';
    },
    canUpdateClient(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'RECEPTION';
    },
    canUpdateClientReport(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'LAWYER'
            || user.role === 'RECEPTION';
    },

    // Billing
    canSeeTheBilling(user) {
        return (
                helper.env.isTrue('VUE_APP_BILLING_ENABLED')
                || helper.env.isTrue('VUE_APP_COUNTERPART_ENABLED')
            )
            && (
                user.role === 'ADMIN'
                || user.role === 'PROVIDER'
                || user.role === 'COUNTERPART'
            );
    },
    canAddPayments(user) {
        return user.role === 'ADMIN';
    },

    canSeeLawyerPlan(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER';
    },

    // Visits
    canCreateVisit(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION'
            || user.role === 'RECEPTION_COUNTERPARTY';
    },

    // Contracts
    canSeeContracts(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'LAWYER'
            || user.role === 'LAWYER_OGPIA'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO';
    },
    canSeeContractsOnMain(user) {
        return user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO'
            || user.role === 'LAWYER_OGPIA'
            || user.role === 'LAWYER_EPO'
            || user.role === 'BUHGALTER';
    },
    canCreateContract(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION';
    },
    canUpdateContract(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO';
    },
    canSaveContract(user) {
        if (this.canUpdateContract(user)) {
            return true;
        }
        return user.role === 'BUHGALTER'
    },
    canDeleteContract(user) {
        return user.role === 'ADMIN';
    },
    canSendContractForDocumentsPreparation(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO'
    },
    canSetDocumentsAreReadyAt(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO'
    },
    canSetTransferredToOGPAAt(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO'
    },
    // eslint-disable-next-line no-unused-vars
    canChangeContractNotManualState(user, state) {
        return user.role === 'ADMIN';
    },
    canDeleteAdditionalPayments(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION';
    },
    canSeeContractCalls(user) {
        return user.role === 'ADMIN'
            || user.role === 'LAWYER'
            || user.role === 'LAWYER_OGPIA'
            || user.role === 'LAWYER_EPO'
            || user.role === 'LAWYER_MANAGER'
            || user.role === 'MANAGER_OKK'
            || user.role === 'MANAGER_OGPIA'
            || user.role === 'MANAGER_EPO';
    },
    canSetContractPhase(user, phase) {
        if (phase.roles.length > 0) {
            return phase.roles.indexOf(user.role) >= 0;
        }
        return true;
    },
    canDeleteContractActions(user) {
        return user.role === 'ADMIN';
    },

    // Acts
    canDeleteAct(act, user) {
        return user.role === 'ADMIN';
    },
    canCreateAct(user) {
        return user.role === 'ADMIN'
            || user.role === 'RECEPTION';
    },

    // Notifications
    canSeeNotifications(user) {
        return user.role !== 'COUNTERPART'
            && user.role !== 'PROVIDER'
            && user.role !== 'RECEPTION_COUNTERPARTY';
    },

    // Settings
    canSeeSettings(user) {
        return user.role === 'ADMIN';
    },
    canChangeOperatorAutoAssign(user) {
        return user.role === 'ADMIN'
            || user.role === 'OPERATOR_MANAGER';
    },
    canSetOutOfPlace(user) {
        return user.role === 'OPERATOR';
    },

    // Users
    canEditUsers(user) {
        return user.role === 'ADMIN';
    },
};

helper.format = {
    currency(number) {
        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 0
        }).format(number);
    },
    number(number) {
        return new Intl.NumberFormat('ru-RU').format(number);
    },
    dateTime(dateTime) {
        return moment(dateTime).format("DD.MM.YY HH:mm");
    },
}

helper.saveViewedEntity = (key, id, ls) => {
    const requestsViewed = ls.get(key, []);
    ls.put(key, [...new Set(requestsViewed).add(id)]);
};

helper.getInstallmentName = (installment) => {
    const installments = {
        HALVA: 'Халва'
    };
    return installments[installment];
};

helper.showBooleanValue = (value) => {
    return value
        ? 'Да'
        : 'Нет';
};

Vue.directive('back', (el) => {
    el.onclick = () => window.history.go(-1)
});

